import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { h, cloneElement, createElement, isValidElement } from 'preact';
import { TLocale, LocaleConfigs } from '@@config/locale/';
import { TLabelsDictionary, TGSTPropertyLabels, TLabel, TGSTProperty } from '@@config/i18n/';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

/**
 * Get the AWS environment (e.g. 'local', 'dev', 'uat', 'prod') based on the corresponding meta tag in the HTML.
 * @method
 * @returns {string} The AWS environment.
 */
export function getAWSEnv(): string {
    const metaTag = document.querySelector('meta[name="aws-env"]');
    if (metaTag) {
        return metaTag?.getAttribute('content') ?? '';
    }
    return '';
}

/**
 * Get the base URL from the 'base' tag in the document.
 * @method
 * @returns {string} The base URL or an empty string if not found.
 */
export function getBaseURL(): string {
    let baseURL: string = '';
    const awsEnv: string = getAWSEnv();
    switch (awsEnv) {
        case 'local':
            baseURL = 'https://wcs-prod.gia.edu/';
            break;
        case 'dev':
            baseURL = 'https://wcs-prod.gia.edu/';
            break;
        case 'uat':
            baseURL = 'https://wcs-prod.gia.edu/';
            break;
        case 'prod':
            baseURL = 'https://www.gia.edu/';
            break;
        default:
            baseURL = 'https://www.gia.edu/';
            break;
    }
    return baseURL;
}

/**
 * Get the RDWB API Host via the corresponding meta tag in the HTML.
 * @method
 * @returns {string} The RDWB host.
 */
export function getRdwbApiHost(): string {
    const metaTag = document.querySelector('meta[name="rdwb-api-host"]');
    if (metaTag) {
        return metaTag?.getAttribute('content') ?? '';
    }
    return '';
}

/**
 * Retrieves the value of a cookie by its name.
 *
 * @function
 * @param {string} cookieName - The name of the cookie to retrieve.
 * @returns {Promise<string>} A Promise that resolves to the value of the cookie as a string.
 * If the cookie is not found, the Promise will resolve with an empty string.
 * @throws {Error} If there is an error while retrieving the cookie, an Error object will be thrown.
 */
export function getCookie(cookieName: string): Promise<string> {
    return new Promise(resolve => {
        const name = cookieName + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');

        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i] || '';
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                resolve(cookie.substring(name.length, cookie.length));
                return; // Exit the function after resolving the Promise
            }
        }

        resolve(''); // Cookie not found, resolve with an empty string
    });
}

/**
 * Creates a description list element with a dotted separator between the title and description.
 *
 * @param {string} dlTitle - The title for the description list item.
 * @param {string} dlDesc - The description for the description list item.
 * @returns {HTMLDListElement} A description list element with the provided title and description.
 */
export function getDescriptionListWithDottedSeparator(dlTitle: string = '', dlDesc: string = ''): HTMLDListElement {
    const descriptionList = document.createElement('dl');
    const dt = document.createElement('dt');
    const dd = document.createElement('dd');

    // Create the horizontal rule we will use for the dotted separator
    const dottedSeparator = document.createElement('hr');

    descriptionList.style.fontWeight = 'bold';

    // Set the text content of the 'dt' and 'dd' elements
    dt.innerHTML = dlTitle;
    dd.innerHTML = dlDesc;

    // Stylize the description list
    descriptionList.classList.add('d-flex');
    descriptionList.classList.add('align-items-baseline');
    descriptionList.classList.add('w-100');

    // Stylize the dotted separator
    dottedSeparator.classList.add('dotted-separator');
    dottedSeparator.classList.add('mx-2');

    // Append the 'dt' and 'dd' elements to the description list
    descriptionList.appendChild(dt);
    descriptionList.appendChild(dottedSeparator);
    descriptionList.appendChild(dd);

    return descriptionList;
}

/**
 * Gets the eReport URL by first removing any non-numeric characters from the stone weight and then constructing the URL.
 *
 * @param {string | number} reportNo
 * @param {string} weight
 * @returns {URL} The eReport URL for the given report number and stone weight.
 */
export function getElectronicReportURL(reportNo: string | number, weight: string): URL {
    const stoneWeight: string = weight.replace(/[^\d.-]/g, '');
    const eReportUrl: string =
        'https://myapps.gia.edu/ReportCheckPortal/getReportData.do?&reportno=' + reportNo + '&weight=' + stoneWeight;
    return new URL(eReportUrl);
}

/**
 * Gets the endpoint URL for the GIA Report Check Related JSON content from Oracle WCS.
 *
 * @function
 * @param {TLocale} locale - The locale to be used in the URL.
 * @returns {URL}
 */
export function getReportCheckRelatedJSONURL(locale: TLocale): URL {
    const baseURL: string = getBaseURL();
    const url = `${baseURL}/sites/Satellite?pagename=GIA/ReportCheck/RelatedJSON&locale=${locale}`;
    return new URL(url);
}

/**
 * Gets the endpoint URL for the GIA Report Check Landing main content from Oracle WCS.
 *
 * @function
 * @param {TLocale} locale - The locale to be used in the URL.
 * @returns {URL}
 */
export function getReportCheckLandingContentURL(locale: TLocale): URL {
    const baseURL: string = getBaseURL();
    const localePath: string = LocaleConfigs[locale].localePath;
    const url = `${baseURL}${
        baseURL.includes('wcs') ? '/sites/gia/' : ''
    }${localePath}report-check-landing-v2?headless=true&token=false`;
    return new URL(url);
}

/**
 * Gets the endpoint URL for the GIA Report Check Header content from Oracle WCS.
 *
 * @function
 * @param {TLocale} locale - The locale to be used in the URL.
 * @returns {URL}
 */
export function getReportCheckHeaderContentURL(locale: TLocale): URL {
    const baseURL: string = getBaseURL();
    const url = `${baseURL}/sites/Satellite?pagename=GIA/ReportCheck/Header&locale=${locale}`;
    return new URL(url);
}

/**
 * Gets the endpoint URL for the GIA Report Check Footer content from Oracle WCS.
 *
 * @function
 * @param {TLocale} locale - The locale to be used in the URL.
 * @returns {URL}
 */
export function getReportCheckFooterContentURL(locale: TLocale): URL {
    const baseURL: string = getBaseURL();
    const url = `${baseURL}sites/Satellite?pagename=GIA/ReportCheck/Foot3r&locale=${locale}`;
    return new URL(url);
}

/**
 * Constructs a URL for the landing page.
 * Deduces relevant information from the window Location object to detect environment and current locale.
 * Provides a cache-busting mechanism by appending a timestamp to the query string in order to ensure 'rdwb-token' is issued.
 *
 * @function
 * @param {Location} location - (ie) window.location
 * @param {string} locale - The locale to add as a query param to the landing page URL (defaults to 'en_US')
 * @returns {URL} - The Landing page URL with cache busting timestamp and locale as query params
 */
export function getLandingPageURL({ origin, search }: Location, locale: string): URL {
    const landingPageURL = new URL(origin),
        params = new URLSearchParams(search),
        timestamp = new Date().getTime();

    landingPageURL.pathname = origin.includes('www.gia.edu') ? '/report-check-landing' : '/report-check-landing.html';

    // Append the locale and cache busting timestamp to the query string
    if (!params.has('locale') && locale !== 'en_US') {
        params.set('locale', locale);
    }
    params.set('nocache', timestamp.toString());

    landingPageURL.search = params.toString();

    return landingPageURL;
}

/**
 * Gets the value of a URL parameter by name.
 *
 * @param {string} name - The name of the parameter to retrieve.
 * @returns {string | null} The value of the parameter if found, or `null` if not found.
 */
export function getParameterByName(name: string): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
}

/**
 * Constructs a URL for the report results page.
 * Also deduces relevant information from the window Location object to detect environment and current locale.
 *
 * @function
 * @param {Location} location - (ie) window.location
 * @param {string|number} reportNumber - The report number to be passed as a URL param to the result page.
 * @returns {URL} - The newly constructed URL object for the report results page.
 */
export function getReportResultsURL({ origin, search }: Location, reportNumber: string | number): URL {
    const reportResultsURL: URL = new URL(origin),
        params: URLSearchParams = new URLSearchParams(search);

    // Sanitize the report number
    const cleanReportNumber: string = _sanitizeReportNumber(reportNumber);

    // Set the report number or new report number as the sole query string parameter
    params.set('reportno', cleanReportNumber);
    reportResultsURL.search = params.toString();

    reportResultsURL.pathname = origin.includes('www.gia.edu') ? '/report-check' : '/report-check.html';

    // Return the report result page URL and preserving any existing query string params
    return reportResultsURL;
}

/**
 * Generates a Tracr URL for a given Tracr ID.
 *
 * @param {string} tracrId - The Tracr ID to create the URL for.
 * Note: The end-user will be navigated to the external Tracr website via the GIA redirect page.
 * @returns {string} The generated Tracr URL.
 */
export function getTracrRedirectURL(tracrId: string): string {
    return `https://www.gia.edu/tracr-redirect?redirectUrl=https://search.tracr.com/${tracrId}`;
}

/**
 * Checks if the URL query string contains a parameter 'qr' with a value of 'true'.
 * @param {string} urlQueryString - The URL query string containing parameters.
 * @returns {boolean} True if the 'qr' parameter equals 'true', false otherwise.
 */
export function isQrParameterTrue(urlQueryString: string = ''): boolean {
    // Use URLSearchParams to parse the query string
    const params: URLSearchParams = new URLSearchParams(urlQueryString);
    // Check if the 'qr' parameter exists and its value is 'true'
    return params.has('qr') && params.get('qr') === 'true';
}

/**
 * Parses the labels from the API response data into structured dictionaries.
 *
 * @param {Record<string, any>} data - The raw data object received from the API.
 * @returns {Object} An object containing two dictionaries:
 *   - labels: A dictionary of structured labels.
 *   - i18n: A dictionary of legacy GST property labels.
 *
 * @typedef {Object} ParsedLabels
 * @property {TLabelsDictionary} labels - A dictionary of structured labels.
 * @property {TGSTPropertyLabels} i18n - A dictionary of legacy GST property labels.
 */
export function parseLabels(data: Record<string, any>): {
    labels: TLabelsDictionary;
    i18n: TGSTPropertyLabels;
} {
    const labels: TLabelsDictionary = {};
    const i18n: TGSTPropertyLabels = {};

    if (data['labels']) {
        for (const [key, value] of Object.entries(data['labels'])) {
            if (key === 'legacy') {
                // Handle legacy labels
                const legacyLabels = value as Record<string, string>;
                for (const [legacyKey, legacyValue] of Object.entries(legacyLabels)) {
                    if (legacyKey.startsWith('gia_www_i18n_')) {
                        i18n[legacyKey as TGSTProperty] = legacyValue;
                    }
                }
            } else if (typeof value === 'object' && value !== null) {
                // Handle regular labels
                labels[key] = value as TLabel;
            }
        }
    }

    return { labels, i18n };
}

/**
 * Parses HTML content and returns it as Preact elements.
 *
 * This function wraps the html-react-parser library, providing default options
 * for use with Preact. It allows parsing HTML strings into Preact components.
 *
 * @param {string} content - The HTML string to be parsed.
 * @param {HTMLReactParserOptions} [options={}] - Additional options to pass to the parser.
 *        These will be merged with the default options.
 * @returns {string|JSX.Element|JSX.Element[]} The parsed content as a string,
 *          Preact element, or array of Preact elements.
 *
 * @example
 * const htmlString = '<p>Hello, <strong>world!</strong></p>';
 * const parsedContent = parseHTML(htmlString);
 * // Use in JSX: <div>{parsedContent}</div>
 */
export function parseHTML(content: string, options: HTMLReactParserOptions = {}): string | JSX.Element | JSX.Element[] {
    const defaultOptions: HTMLReactParserOptions = {
        library: {
            cloneElement,
            createElement,
            isValidElement,
            // Include h if needed, though it's not explicitly required by the type
            h,
        },
        ...options,
    };

    return parse(content, defaultOptions);
}

/**
 * Parses the locale from the given query string.
 * If the locale in the URL params is undefined or invalid returns ''.
 *
 * @function
 * @param {string} urlQueryString - The query string containing URL parameters.
 * @returns {string} The locale (e.g. 'en_US').
 */
export function parseLocale(urlQueryString: string = ''): string {
    const urlParams: URLSearchParams = new URLSearchParams(urlQueryString);
    const locale: string = urlParams.get('locale') || '';

    return locale;
}

/**
 * Get and parse browser local storage into an object of key-value pairs.
 *
 * @function
 * @param {Storage} localStorage - The browser local storage object.
 * @returns {Record<string, string>} - An object containing key-value pairs from the local storage.
 * Returns an empty object if no data is found in the local storage.
 */
export function parseLocalStorage(localStorage: Storage): Record<string, string> {
    const storedData: Record<string, any> = {};

    // Iterate through the local storage items and add them to the object
    for (let i = 0; i < localStorage.length; i++) {
        const key: string | null = localStorage.key(i);
        if (!key) continue;

        const value: string = localStorage.getItem(key) || '';

        storedData[key] = value;
    }

    return storedData;
}

/**
 * Parses the report number from the given URL query string.
 * If the report number is not found in the URL params, it returns an empty string.
 *
 * @function
 * @param {string} urlQueryString - The URL query string containing parameters.
 * @returns {string} The report number, or an empty string if not found.
 */
export function parseReportNumber(urlQueryString: string = ''): string {
    const urlParams: URLSearchParams = new URLSearchParams(urlQueryString);
    const reportno: string = urlParams.get('reportno') || '';

    return reportno;
}

/**
 * Parses the given URL query parameters and saves them individually by their parameter names in the browser local storage
 *
 * @function
 * @param {string} search - window.location.search
 * @returns {void} This function does not return any value.
 * @example
 * // If the URL is http://example.com/?name=Jonathan&age=40&location=New%20York
 * // The function will save the following key-value pairs in the local storage:
 * // Key: "name", Value: "Jonathan"
 * // Key: "age", Value: "40"
 * // Key: "location", Value: "New York"
 */
export function queryParamsToLocalStorage(search: string): void {
    // Get the query string
    const queryString: string = search.slice(1);

    // Split the query string into individual parameters
    const queryParams: string[] = queryString.split('&');

    // Create an object to store the parsed parameters
    const parsedParams: Record<string, string> = {};

    // Iterate through the parameters and save them to the object
    queryParams.forEach(param => {
        const [key, value] = param.split('=');
        if (key && value) {
            parsedParams[key] = decodeURIComponent(value);
        }
    });

    // Save the parsed parameters to the local storage
    for (const key in parsedParams) {
        if (key in parsedParams && parsedParams[key] !== undefined) {
            localStorage.setItem(key, parsedParams[key] || '');
        }
    }
}

/**
 * Sets a cookie with the specified name, value, and expiration time.
 *
 * @function
 * @param {string} cookieName - The name of the cookie to be set.
 * @param {string} cookieValue - The value to be stored in the cookie.
 * @param {number} expirationDays - The number of days until the cookie should expire.
 * @returns {void} This function does not return any value.
 *
 * @example
 * // Set a cookie named 'username' with value 'PeterPan' that expires in 7 days.
 * setCookie('username', 'PeterPan', 7);
 */
export function setCookie(cookieName: string, cookieValue: string, expirationDays: number): void {
    const date: Date = new Date();
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    const expires: string = 'expires=' + date.toUTCString();

    // Set the cookie with the provided name, value, and expiration time.
    document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/';
}

/**
 * Updates the Google Tag Manager (GTM) dataLayer with a specific metric key and value.
 *
 * @param {string} metricKey - The metric key to update in the dataLayer.
 * @param {string} metricValue - The metric value to update in the dataLayer.
 * @returns {void}
 */
export function updateDataLayerWithMetric(metricKey: string, metricValue: string = ''): void {
    // Create an object with the dynamic metric key-value pair
    const dataPoint = {
        [metricKey]: metricValue,
    };

    // Check if GTM (Google Tag Manager) is defined
    if (window.dataLayer) {
        // Push the metric data to the dataLayer
        window.dataLayer.push(dataPoint);
    }
}

/**
 * @function
 * @param {string} locale - The locale to be used within switch to set the UserWay widget language
 * @returns {void} This function does not return any value.
 */
export function updateUserWayWidgetLanguage(locale: string): void {
    let userWayLanguageCode: string = 'en-US';
    /* Supported languages can be found here: https://userway.org/docs/#widget-settings */
    switch (locale) {
        case 'ja_JP':
            userWayLanguageCode = 'ja';
            break;
        case 'zh_CN':
            userWayLanguageCode = 'zh';
            break;
        default:
            userWayLanguageCode = 'en-US';
    }

    /* Change widget language once it has initialized */
    document.addEventListener('userway:init_completed', function (event: Event) {
        // @ts-ignore
        const instance = event.detail.userWayInstance;
        if (instance) instance.changeWidgetLanguage(userWayLanguageCode);
    });
}

/**
 * Sanitizes the report number.
 *
 * @function
 * @private
 * @param {string|number} reportNumber - The report number to sanitize.
 * @returns {string} The sanitized report number.
 */
function _sanitizeReportNumber(reportNumber: string | number): string {
    const maxLn = 15;
    // Convert to string (in case receiving number)
    let sanitized: string = reportNumber.toString();
    // Remove any non-alphanumeric characters, enforce max length, and capitalize the string (for JG reports)
    sanitized = sanitized
        .replace(/[^a-zA-Z0-9]/g, '')
        .trim()
        .slice(0, maxLn)
        .toUpperCase();

    return sanitized;
}
