import { h, FunctionalComponent } from 'preact';
import Card from '@@components/Card/Card.tsx';
import { TCard, TRelated } from '@@config/wcs/';

type TRelatedProps = {
    readonly data: TRelated;
};

const Related: FunctionalComponent<TRelatedProps> = ({ data }) => {
    const { RELATED_TITLE, CARDS } = data;

    return (
        <section className='related-content-cards'>
            {/* <hr className='dotted-double' /> */}
            <div className='row'>
                <div className='col'>
                    <h3>{RELATED_TITLE}</h3>
                </div>
            </div>
            {CARDS && (
                <div className='row row-cols-1 row-cols-sm-2 row-cols-md-4'>
                    {CARDS.map((card: TCard) => {
                        return <Card data={card} key={card.ASSET_ID.CID} />;
                    })}
                </div>
            )}
        </section>
    );
};

export type { TRelatedProps };
export default Related;
