import { Locale, Locales, TLocale, LocaleConfigs, TLocaleConfig } from './types';

/**
 * Type-safety method to determine if a given input is a valid locale
 *
 * @param {string} input - The input to validate
 * @return {boolean} - Indicating if the input is a valid locale
 */
export function isLocale(input: string): input is TLocale {
    return (Object.keys(Locales) as TLocale[]).includes(input as TLocale);
}

export { Locale, Locales, LocaleConfigs };
export type { TLocale, TLocaleConfig };
