import { h, FunctionalComponent } from 'preact';
import { TCard } from '@@config/wcs/';
import { parseHTML } from '@@utils/utils.ts';

type TCardProps = {
    readonly data: TCard;
};

const Card: FunctionalComponent<TCardProps> = ({ data }) => {
    const { CTA, IMAGE, TITLE } = data;

    return (
        <div className='col card-group'>
            <a href={CTA?.TARGET_URL} className='card card--dynamic position-relative mb-sm-4 mb-md-0 mb-lg-0 border-0'>
                {IMAGE && (
                    <div className='position-relative'>
                        <img className='card-img-top' alt={IMAGE.ALT_TEXT} src={IMAGE.SRC} />
                        <div className='card-img-overlay position-absolute w-100 h-100'></div>
                    </div>
                )}

                <div className='card-body text-left px-0 py-2'>
                    <h5 className='card-title text-left text-uppercase font-weight-bold mb-1'>{parseHTML(TITLE)}</h5>
                    <a
                        href={CTA?.TARGET_URL}
                        className='btn btn-light btn-light--dynamic d-none d-sm-inline-flex position-relative mt-1'
                        data-icon='arrow-right'
                        tabIndex={-1}
                    >
                        <span>{CTA?.LINK_TEXT}</span>
                    </a>
                </div>
            </a>
        </div>
    );
};

export type { TCardProps };
export default Card;
