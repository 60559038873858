// Must be the first import
import 'preact/debug';
// Or if you just want the devtools bridge (~240B) without other
// debug code (useful for production sites)
import 'preact/devtools';
import Related from '@@components/Related/Related.tsx';
import { h, render } from 'preact';
import 'dayjs/locale/zh-cn.js';
import 'dayjs/locale/ja.js';

const Global = (function () {
    // Private members
    /**
     * @constant {Object} _localeConfigs - Locale configurations to simplify getting and setting locale-dependent variables
     * @private
     */
    const _localeConfigs = {
        // BCP 47 Language Codes List (https://appmakers.dev/bcp-47-language-codes-list/)
        en_US: {
            localePath: '',
            localeAbbr: 'en',
            localeBcp47: 'en-US',
        },
        zh_CN: {
            localePath: 'CN/',
            localeAbbr: 'cn',
            localeBcp47: 'zh-CN',
        },
        ja_JP: {
            localePath: 'JP/',
            localeAbbr: 'jp',
            localeBcp47: 'ja-JP',
        },
    };

    /**
     * Localizes the current URL by modifying the locale query parameter.
     *
     * @function
     * @param {string} targetLocale - The target locale to set in the URL (e.g. 'en_US').
     * @param {Location} location - (ie) window.location
     * @returns {string} The localized URL with the updated 'locale' query parameter.
     * @private
     */
    const _localizeCurrentURL = function (targetLocale, location) {
        const { origin, pathname, search } = location;

        /**
         * @type {URLSearchParams}
         */
        const urlParams = new URLSearchParams(search);

        // Set the 'locale' query parameter to the target locale.
        urlParams.set('locale', targetLocale);

        /**
         * @type {string}
         */
        const qs = urlParams.toString();

        /**
         * @type {string}
         */
        const newURL = origin + pathname + '?' + qs;

        return newURL;
    };

    // /**
    //  * Dynamically imports a CSS file by creating a link element and appending it to the DOM.
    //  *
    //  * @function
    //  * @param {string} cssSrc - The URL of the CSS file to import.
    //  * @returns {void} This function does not return any value.
    //  * @private
    //  */
    // const _importCSS = function (cssSrc) {
    //     /** @type {HTMLLinkElement} */
    //     const $link = document.createElement('link');
    //     $link.href = cssSrc;
    //     $link.rel = 'stylesheet';
    //     document.body.insertAdjacentElement('afterbegin', $link);
    // };

    /**
     * Dynamically imports a JavaScript file by creating a script element and appending it to the DOM.
     *
     * @function
     * @param {string} jsSrc - The URL of the JavaScript file to import.
     * @param {boolean} defer - Whether or not to defer the execution of the script. Defaults to true.
     * @returns {void} This function does not return any value.
     * @private
     */
    const _importJS = function (jsSrc, defer = true) {
        /** @type {HTMLScriptElement} */
        const $script = document.createElement('script');
        $script.src = jsSrc;
        $script.defer = defer;
        document.querySelector('body').insertAdjacentElement('beforeend', $script);
    };

    // Public members
    return {
        /** @property {string} */
        reportno: '',
        /** @property {string} */
        locale: 'en_US',
        /** @property {boolean} */
        isDev: false,
        /** @property {string} */
        awsEnv: '',
        /** @property {string} */
        baseURL: 'https://www.gia.edu/',

        /**
         * Sets the report number.
         * @method
         * @param {string|number} [reportNumber=''] - The report number to set. Defaults to an empty string.
         */
        setReportNo: function (reportNumber = '') {
            // Validate that the report number is a string or a number before setting it
            if (typeof reportNumber === 'string' || typeof reportNumber === 'number') {
                this.reportno = reportNumber;
            }
        },

        /**
         * Gets the current report number.
         * @method
         * @returns {string|number} The current report number.
         */
        getReportNo: function () {
            return this.reportno;
        },

        /**
         * Sets the base URL by retrieving it from the 'base' tag in the document.
         * @method
         * @returns {void}
         */
        setBaseURL: function () {
            const baseTag = document.getElementsByTagName('base')[0];
            if (baseTag) {
                this.baseURL = baseTag.getAttribute('href');
            }
        },

        /**
         * Gets the currently set base URL.
         * @method
         * @returns {string} The current base URL.
         */
        getBaseURL: function () {
            return this.baseURL;
        },

        /**
         * Set the AWS environment (e.g. 'local', 'dev', 'uat', 'prod') based on the corresponding meta tag in the HTML.
         * @method
         * @returns {void}
         */
        setAWSEnv: function () {
            const metaTag = document.querySelector('meta[name="aws-env"]');
            if (metaTag) {
                this.awsEnv = metaTag.getAttribute('content');
            }
        },

        /**
         * Get the AWS environment (e.g. 'local', 'dev', 'uat', 'prod') based on the corresponding meta tag in the HTML.
         * @method
         * @returns {string} The AWS environment.
         */
        getAWSEnv: function () {
            if (this.awsEnv) {
                return this.awsEnv;
            }
            const metaTag = document.querySelector('meta[name="aws-env"]');
            if (metaTag) {
                return metaTag.getAttribute('content');
            }
            return '';
        },

        /**
         * Sets the locale for the application if it is one of the acceptable options.
         *
         * @param {string} locale - The locale to set.
         * @throws {Error} Throws an error if the provided locale is not acceptable.
         */
        setLocale: function (locale) {
            const acceptableLocales = ['en_US', 'zh_CN', 'ja_JP'];

            if (acceptableLocales.includes(locale)) {
                this.locale = locale;
            } else {
                throw new Error(`Invalid locale: ${locale}. Acceptable locales are ${acceptableLocales.join(', ')}.`);
            }
        },

        /**
         * Retrieves the currently set locale.
         *
         * @returns {string} The currently set locale.
         */
        getLocale: function () {
            return this.locale;
        },

        /**
         * Sets the HTML lang attribute to the specified locale value.
         *
         * @param {string} [locale='en_US'] - The locale to set as the HTML lang attribute. Defaults to 'en_US' if not provided.
         */
        setHTMLLang: function (locale = 'en_US') {
            const htmlElement = document.documentElement;

            if (htmlElement) {
                htmlElement.lang = _localeConfigs[locale].localeBcp47;
            } else {
                throw new Error('HTML element not found. Cannot set lang attribute.');
            }
        },

        /**
         * Get the abbreviation of the current locale.
         *
         * @method
         * @param {string} [locale='en_US'] - The locale to get the corresponding abbreviation for. Defaults to 'en_US' if not provided.
         * @returns {string} The abbreviation of the current locale (e.g., 'en', 'cn', 'jp')
         */
        getLocaleAbbr: function (locale = 'en_US') {
            return _localeConfigs[locale].localeAbbr;
        },

        /**
         * Get the path associated with the current locale.
         *
         * @method
         * @param {string} [locale='en_US'] - The locale to get the corresponding locale path for. Defaults to 'en_US' if not provided.
         * @returns {string} The URL path associated with the current locale. Used in the curation of GIA.edu specific URLs.
         * Possible path values are restricted to ('', '/CN', '/JP') where '' is the default locale (ie) 'en_US'
         */
        getLocalePath: function (locale = 'en_US') {
            return _localeConfigs[locale].localePath;
        },

        /**
         * Fetches the HTML content from the specified URL using the Fetch API.
         *
         * @method
         * @param {string} url - The URL from which to fetch the HTML content.
         * @param {Object} [fetchOpts={}] - Optional Fetch API options object.
         * @returns {Promise<string>} A Promise that resolves to the fetched HTML content as a string.
         * If an error occurs during the fetch, the Promise will be rejected with the error.
         * @throws {Error} If the fetch fails or encounters an error, an Error object will be thrown.
         */
        fetchHTML: async function (url, fetchOpts = {}) {
            try {
                const res = await fetch(url, fetchOpts);
                return await res.text();
            } catch (error) {
                console.error('Error fetching HTML:', error);
                throw error;
            }
        },

        /**
         * Fetches JSON data from the specified URL using the Fetch API.
         *
         * @method
         * @param {string} url - The URL from which to fetch the JSON data.
         * @param {Object} [fetchOpts={}] - Optional Fetch API options object.
         * @returns {Promise<Object>} A Promise that resolves to the parsed JSON data.
         * If an error occurs during the fetch or parsing, the Promise will be rejected with the error.
         * @throws {Error} If the fetch or parsing fails, an Error object will be thrown.
         */
        fetchJSON: async function (url, fetchOpts = {}) {
            try {
                const res = await fetch(url, fetchOpts);
                const contentType = res.headers.get('content-type');

                if (contentType && contentType.includes('application/json')) {
                    return await res.json();
                }
            } catch (error) {
                console.error('Error fetching JSON:', error);
                throw error;
            }
        },

        /**
         * Fetches HTML content from the specified URL using the Fetch API and renders it into the DOM.
         *
         * @method
         * @param {string} url - The URL from which to fetch the HTML content.
         * @param {string} containerSelector - The CSS selector for the container element where the HTML content will be rendered.
         * @param {Object} [fetchOpts={}] - Optional Fetch API options object.
         * @returns {Promise<string>} A Promise that resolves to the fetched HTML content as a string.
         * If an error occurs during the fetch or rendering, the Promise will be rejected with the error.
         * @throws {Error} If the fetch or rendering fails, an Error object will be thrown.
         */
        loadAndRenderHTML: async function (url, containerSelector, fetchOpts = {}) {
            try {
                const res = await fetch(url, fetchOpts);
                const html = await res.text();
                document.querySelector(containerSelector).innerHTML = html;
                return html;
            } catch (error) {
                console.error('Error fetching and rendering content:', error);
                throw error;
            }
        },

        /**
         * Sets the locale for the Day.js library to use for date formatting.
         *
         * @method
         * @param {string} [locale='en_US'] - The locale to set for Day.js. Defaults to 'en_US' if not provided.
         * @returns {void} This function does not return any value.
         */
        setLocaleDayJS: function (locale = 'en_US') {
            /** @type {string} */
            let localeForDayJS;

            switch (locale) {
                case 'ja_JP':
                    localeForDayJS = 'ja';
                    break;
                case 'zh_CN':
                    localeForDayJS = 'zh-cn';
                    break;
                default:
                    localeForDayJS = 'en';
                    break;
            }
            // Set day.js locale globally (for now only used for report issue date formatting)
            dayjs.locale(localeForDayJS);
            this.localeForDayJS = localeForDayJS;
        },

        /**
         * Loads global resources such as external CSS and JS files based on the environment.
         *
         * @method
         * @returns {void} This function does not return any value.
         */
        loadGlobals: function () {
            /** @type {string[]} */
            const externalSources = [
                `${this.getBaseURL()}dist/_report-check-bundle.js?timestamp=1702489265262`,
                'https://cdn.userway.org/widget.js',
                // 'https://www.gia.edu/dist/_app.css',
            ];
            console.info('externalSources: ', externalSources);

            // Dynamically load external CSS and JS files
            for (let src of externalSources) {
                // if (src.includes('.css')) _importCSS(src);
                if (src.includes('.js')) _importJS(src);
            }
        },

        /**
         * Loads the global header by fetching and rendering it from the appropriate URL based on the environment and locale.
         *
         * @method
         * @returns {Promise<void>} A Promise that resolves when the header is fetched and rendered.
         */
        loadHeader: async function () {
            const url = `${this.getBaseURL()}sites/Satellite?pagename=GIA/ReportCheck/Header&locale=${this.getLocale()}`;
            console.debug('loadHeader url: ', url);

            await this.loadAndRenderHTML(url, '#global-header', {
                importance: 'high',
            });
            document.querySelector('.global-header').classList.add('ready');
        },

        /**
         * Loads the global footer by fetching and rendering it from the appropriate URL based on the environment and locale.
         *
         * @method
         * @returns {Promise<void>} A Promise that resolves when the footer is fetched and rendered.
         */
        loadFooter: async function () {
            const url = `${this.getBaseURL()}sites/Satellite?pagename=GIA/ReportCheck/Foot3r&locale=${this.getLocale()}`;
            console.debug('loadFooter url: ', url);

            await this.loadAndRenderHTML(url, '#footer', { importance: 'low' });
            // var $localeTrigger = document.querySelector('#dropdownLocaleSelector');
            var $localeLinks = document.querySelectorAll('.language-select .language-select-url');
            // Update the links in the locale selector
            $localeLinks.forEach(function ($link) {
                // Get the target locale from the link's data attribute
                const targetLocale = $link.getAttribute('data-dimension');

                // Create new localized version of the current URL
                const localizedURL = _localizeCurrentURL(targetLocale, window.location);

                // Update the link's href
                $link.href = localizedURL;
            });
        },

        /**
         * Loads related content by fetching and rendering it from the appropriate URL based on the environment and locale.
         *
         * @method
         * @returns {Promise<void>} A Promise that resolves when the related content is fetched and rendered.
         *
         */
        loadRelated: async function () {
            // const url = `${this.getBaseURL()}sites/Satellite?pagename=GIA/ReportCheck/Related&c=Page&cid=1495313612135&locale=${this.getLocale()}`;
            const url = `${this.getBaseURL()}sites/Satellite?pagename=GIA/ReportCheck/RelatedJSON&locale=${this.getLocale()}`;
            console.info('loadRelated url: ', url);

            try {
                const relatedData = await this.fetchJSON(url, { importance: 'low' });
                document.getElementById('related').innerHTML = ''; /* Clear the container */
                render(<Related data={relatedData} />, document.getElementById('related'));
            } catch (error) {
                console.error('Error on loadRelated:', error);
                throw error;
            }
        },

        /**
         * Loads notification/(s) from the specified API and renders them within the Notification Container.
         *
         * @method
         * @param {string} locale - The locale to use for rendering the notification received in the correct language.
         * @returns {void} This function does not return a value.
         */
        loadNotifications: function (locale) {
            /**
             * Represents the global banner container.
             * @typedef {HTMLSectionElement} GlobalBannerContainer
             */

            /**
             * Represents a notification container.
             * @typedef {HTMLDivElement} NotificationContainer
             */

            /**
             * Represents a notification item.
             * @typedef {HTMLSpanElement} NotificationItem
             */

            /**
             * Represents a notification link.
             * @typedef {HTMLAnchorElement} NotificationLink
             */
            // Fetch notifications from the API
            fetch('https://notification.gia.edu/api/corporate')
                .then(response => response.json())
                .then(result => {
                    /** @type {GlobalBannerContainer} */
                    const globalBanner = document.getElementById('global-banner');

                    /** @type {number} */
                    const numberOfNotifications = result.length;

                    /** @type {number} */
                    const heightForEachNotification = 35;

                    /** @type {string} */
                    const pixelHeightForGlobalBanner = numberOfNotifications * heightForEachNotification + 'px';

                    if (numberOfNotifications > 0) {
                        setTimeout(() => {
                            globalBanner.style.height = pixelHeightForGlobalBanner;
                            globalBanner.setAttribute('aria-hidden', false);
                        }, 1000);
                        // TODO: Possibly move above to end of this function

                        /** @type {NotificationContainer} */
                        const notifContainer = document.querySelector('#global-banner .messages');

                        for (let i = 0; i < result.length; i++) {
                            for (let j = 0; j < result[i].content.length; j++) {
                                if (result[i].content[j].locale.replace('-', '_') === locale) {
                                    /** @type {string} */
                                    const notification = result[i].content[j].bodyCopy;
                                    if (result[i].content[j].url && result[i].content[j].bodyCopy !== '') {
                                        /** @type {NotificationItem} */
                                        const span = document.createElement('span');

                                        span.className = 'message';

                                        /** @type {NotificationLink} */
                                        const a = document.createElement('a');

                                        // a.className = 'clamp-this blue-link';
                                        a.href = result[i].content[j].url;
                                        a.textContent = notification;
                                        span.appendChild(a);
                                        notifContainer.appendChild(span);
                                    } else if (result[i].content[j].bodyCopy !== '') {
                                        /** @type {NotificationItem} */
                                        const b = document.createElement('b');
                                        /** @type {HTMLSpanElement} */
                                        const span = document.createElement('span');
                                        span.className = 'clamp-this';
                                        span.textContent = notification;
                                        b.appendChild(span);
                                        notifContainer.appendChild(b);
                                    }
                                }
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error('An error occurred:', error);
                });
        },

        /**
         * Attaches a click event handler to the close button of the global banner.
         * The click event handler will hide the banner bu setting the aria-hidden attribute of the banner to true.
         */
        attachCloseNotifClickHandler: () => {
            /** @type {HTMLSectionElement} */
            const globalBanner = document.getElementById('global-banner');

            /** @type {HTMLButtonElement} */
            const closeButton = document.getElementById('global-banner-close');

            /**
             * Click event handler for the close button.
             * Hides the banner and sets the aria-pressed attribute of the close button to true.
             */
            closeButton.addEventListener('click', () => {
                closeButton.setAttribute('aria-pressed', 'true');
                globalBanner.setAttribute('aria-hidden', 'true');
            });
        },
        /**
         * Adds social icon swap functions to the global window object.
         * This method defines three functions for the social icon swapping and object finding, then adds them to the
         * window object if it's available.
         *
         * @method
         * @memberof Global
         * @returns {void}
         */
        socialIconSwapFnsToWindow: () => {
            /**
             * Finds an object in the document.
             * @function
             * @param {string} n - The name or id of the object to find.
             * @param {Document} [d=document] - The document to search in.
             * @returns {HTMLElement|null} The found object or null if not found.
             */
            function MM_findObj(n, d) {
                //v4.01
                var p, i, x;
                if (!d) d = document;
                if ((p = n.indexOf('?')) > 0 && parent.frames.length) {
                    d = parent.frames[n.substring(p + 1)].document;
                    n = n.substring(0, p);
                }
                if (!(x = d[n]) && d.all) x = d.all[n];
                for (i = 0; !x && i < d.forms.length; i++) x = d.forms[i][n];
                for (i = 0; !x && d.layers && i < d.layers.length; i++) x = MM_findObj(n, d.layers[i].document);
                if (!x && d.getElementById) x = d.getElementById(n);
                return x;
            }

            /**
             * Restores swapped images to their original sources.
             * @function
             */
            function MM_swapImgRestore() {
                //v3.0
                var i,
                    x,
                    a = document.MM_sr;
                for (i = 0; a && i < a.length && (x = a[i]) && x.oSrc; i++) x.src = x.oSrc;
            }

            /**
             * Swaps images based on provided arguments.
             * @function
             * @param {...string} args - Variable number of arguments for image swapping.
             */
            function MM_swapImage() {
                var i,
                    j = 0,
                    x,
                    a = Array.from(arguments);
                document.MM_sr = [];
                for (i = 0; i < a.length - 2; i += 3) {
                    if ((x = MM_findObj(a[i])) !== null) {
                        document.MM_sr[j++] = x;
                        if (!x.oSrc) x.oSrc = x.src;
                        x.src = a[i + 2];
                    }
                }
            }

            if (window !== undefined) {
                window.MM_swapImgRestore = MM_swapImgRestore;
                window.MM_findObj = MM_findObj;
                window.MM_swapImage = MM_swapImage;
            }
        },
        /**
         * Initialize the AWS Real User Monitoring (RUM) client.
         * @method
         * @returns {void} This function does not return any value.
         */
        initRUM: () => {
            (function (n, i, v, r, s, c, x, z) {
                x = window.AwsRumClient = { q: [], n: n, i: i, v: v, r: r, c: c };
                window[n] = function (c, p) {
                    x.q.push({ c: c, p: p });
                };
                z = document.createElement('script');
                z.async = true;
                z.src = s;
                document.head.insertBefore(z, document.head.getElementsByTagName('script')[0]);
            })(
                'cwr',
                '20a79d84-91e1-4c69-ba49-e532f7edff86',
                '1.0.0',
                'us-west-2',
                'https://client.rum.us-east-1.amazonaws.com/1.14.0/cwr.js',
                {
                    sessionSampleRate: 1,
                    guestRoleArn:
                        'arn:aws:iam::156526949239:role/RUM-Monitor-us-west-2-156526949239-2373525369961-Unauth',
                    identityPoolId: 'us-west-2:14950b33-9a15-4372-8e35-9ae4d86a4719',
                    endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
                    telemetries: ['performance', 'errors', 'http'],
                    allowCookies: true,
                    enableXRay: false,
                }
            );
        },
    };
})();

export default Global;
