/**
 * An enumeration of locales
 * @enum
 */
export enum Locales {
    en_US = 'en_US',
    zh_CN = 'zh_CN',
    ja_JP = 'ja_JP',
}

/**
 * An enumeration of locales exported as constant readonly strings
 *
 * @const
 * @enum
 */
export const Locale = {
    ...Locales,
} as const;

/**
 * A string literal type representing a type of locale
 *
 * @type
 */
export type TLocale = keyof typeof Locales;

/**
 * A type representing a other string values derived from or shared with a Locale
 *
 * @type
 */
export type TLocaleConfig = {
    localePath: string;
    localeAbbr: string;
    localeBcp47: string;
};

/**
 * A helpful mapping of locales to their associated or derived string values
 * @const
 * @enum
 */
export const LocaleConfigs: { [key in Locales]: TLocaleConfig } = {
    [Locales.en_US]: {
        localePath: '',
        localeAbbr: 'en',
        localeBcp47: 'en-US',
    },
    [Locales.zh_CN]: {
        localePath: 'CN/',
        localeAbbr: 'cn',
        localeBcp47: 'zh-CN',
    },
    [Locales.ja_JP]: {
        localePath: 'JP/',
        localeAbbr: 'jp',
        localeBcp47: 'ja-JP',
    },
} as const;
